var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.company.country
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            { staticClass: "text-center mb-5" },
            [
              _c(
                "v-card-text",
                [
                  _c("cdn-img", {
                    attrs: {
                      src: _vm.company.image,
                      alt: _vm.company.name,
                      fluid: "",
                    },
                  }),
                  _c("h3", { staticClass: "mb-1 text-uppercase" }, [
                    _vm._v(" " + _vm._s(_vm.company.name) + " "),
                  ]),
                  _c(
                    "h3",
                    _vm._l(_vm.company.companyActivities, function (ca) {
                      return _c(
                        "span",
                        {
                          key: ca.id,
                          staticClass:
                            "blue-grey--text text-uppercase text-caption",
                        },
                        [_vm._v(_vm._s(_vm.translate(ca.activity.sector.name)))]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-btn",
                    { attrs: { to: "/company/" + _vm.companyId + "/edit" } },
                    [_vm._v(_vm._s(_vm.$t("actions.edit")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tabs",
            {
              staticStyle: { position: "sticky", top: "63px", "z-index": "1" },
              model: {
                value: _vm.currentTab,
                callback: function ($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab",
              },
            },
            [
              _c("v-tab", [
                _vm._v(" " + _vm._s(_vm.$t("company.tabs.information")) + " "),
              ]),
              _vm.dataEnabled
                ? _c("v-tab", [
                    _vm._v(" " + _vm._s(_vm.$t("company.tabs.data")) + " "),
                  ])
                : _vm._e(),
              _vm.dataEnabled
                ? _c("v-tab", [
                    _vm._v(" " + _vm._s(_vm.$t("company.tabs.analysis")) + " "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currentTab === 2,
                            expression: "currentTab === 2",
                          },
                        ],
                        staticClass: "text-center",
                      },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: { bottom: "", "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticStyle: {
                                                "margin-left": "8px",
                                              },
                                              attrs: { small: "" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                },
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("fa fa-caret-down")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1232854018
                            ),
                          },
                          [
                            _c(
                              "v-list",
                              { staticClass: "analysis-menu" },
                              [
                                _vm._l(_vm.data.axes, function (axe) {
                                  return [
                                    _c(
                                      "v-list-item",
                                      {
                                        key: "sca" + axe.id,
                                        attrs: { link: "" },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.goto(
                                                  "title-axe-" + axe.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(_vm.translateName(axe))
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._l(axe.themes, function (theme) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          {
                                            key: "sct" + theme.id,
                                            attrs: { link: "" },
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.goto(
                                                      "title-theme-" + theme.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.translateName(theme)
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    }),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.company.hasInvestments
                ? _c("v-tab", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("company.tabs.investments")) + " "
                    ),
                  ])
                : _vm._e(),
              _vm.company.hasStock
                ? _c("v-tab", [
                    _vm._v(" " + _vm._s(_vm.$t("company.tabs.stock")) + " "),
                  ])
                : _vm._e(),
              _c("v-tab", [
                _vm._v(" " + _vm._s(_vm.$t("company.tabs.downloads")) + " "),
              ]),
              _c(
                "v-tabs-items",
                {
                  staticClass: "mt-4",
                  attrs: { touchless: "" },
                  model: {
                    value: _vm.currentTab,
                    callback: function ($$v) {
                      _vm.currentTab = $$v
                    },
                    expression: "currentTab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    [
                      false
                        ? _c(
                            "v-card",
                            [
                              _c("v-card-title", { staticClass: "pb-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("models.company.contacts")) +
                                    " "
                                ),
                              ]),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-list",
                                    { attrs: { "two-line": "" } },
                                    [
                                      _vm._l(
                                        _vm.company.contacts,
                                        function (contact, index) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              { key: contact.title },
                                              [
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            contact.name
                                                          ),
                                                        },
                                                      }),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        {
                                                          staticClass:
                                                            "text--primary",
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              contact.title
                                                            ),
                                                          },
                                                        }
                                                      ),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              contact.phone +
                                                                " &mdash; " +
                                                                contact.email
                                                            ),
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            small: "",
                                                          },
                                                        },
                                                        [
                                                          contact.phone
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "grey lighten-1",
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " fa-phone "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            small: "",
                                                          },
                                                        },
                                                        [
                                                          contact.email
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "yellow darken-3",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " fa-envelope "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                              ],
                                              2
                                            ),
                                            index <
                                            _vm.company.contacts.length - 1
                                              ? _c("v-divider", { key: index })
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "7" } },
                            [
                              _c(
                                "v-card",
                                [
                                  _c("v-card-title", [
                                    _vm._v(" Conformité MSI 20000 "),
                                  ]),
                                  _c("v-card-subtitle", [
                                    _vm._v(_vm._s(_vm.latestYear)),
                                  ]),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-col", { attrs: { md: "6" } }),
                                          _c(
                                            "v-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c("score-gauge", {
                                                attrs: {
                                                  scores: _vm.company.scores,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("score-evolution", {
                                    attrs: { scores: _vm.company.scores },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { md: "5" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "mb-5" },
                                [
                                  _c("v-card-title", [_vm._v("Activités")]),
                                  _c(
                                    "v-card-text",
                                    _vm._l(
                                      _vm.company.companyActivities,
                                      function (ca) {
                                        return _c("span", { key: ca.id }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.translate(ca.activity.name)
                                            )
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list",
                                [
                                  _vm.company.address
                                    ? _c(
                                        "v-list-item",
                                        [
                                          _c("v-list-item-icon", [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "20px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.flag(
                                                      _vm.company.country.code
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  _vm._s(_vm.company.address)
                                                ),
                                              ]),
                                              _c("v-list-item-subtitle", [
                                                _vm._v(
                                                  _vm._s(_vm.company.zipcode) +
                                                    " " +
                                                    _vm._s(_vm.company.city) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.translate(
                                                        _vm.company.country.name
                                                      )
                                                    )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.company.phone
                                    ? _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v(" mdi-phone "),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  _vm._s(_vm.company.phone)
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.company.url
                                    ? _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v(" mdi-link "),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(_vm.company.url)),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.company.origin != "manual"
                                    ? _c(
                                        "v-list-item",
                                        {
                                          key: "sca" + _vm.company.id,
                                          attrs: { link: "" },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v(" mdi-link "),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.gotoUrl(
                                                    _vm.front +
                                                      "/enterprise/" +
                                                      _vm.company.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  "URL analyse Smart Msi "
                                                ),
                                              ]),
                                              _c(
                                                "v-list-item-subtitle",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(_vm._s(_vm.front)),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.dataEnabled
                    ? _c(
                        "v-tab-item",
                        [
                          _c("company-upload-financial-statements", {
                            attrs: { company: _vm.company },
                            on: {
                              done: function ($event) {
                                return _vm.uploadedFinancialStatements()
                              },
                            },
                          }),
                          false && _vm.dataLoaded
                            ? _c("Data", {
                                attrs: { company: _vm.company, data: _vm.data },
                                on: {
                                  instrumentsChange: _vm.onInstrumentsChange,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataEnabled
                    ? _c(
                        "v-tab-item",
                        [
                          _vm.dataLoaded
                            ? _c("company-view-analysis", {
                                attrs: { company: _vm.company, data: _vm.data },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.company.hasInvestments
                    ? _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("company-investments", {
                                    attrs: { company: _vm.company },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.company.hasStock
                    ? _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("stock-exchange-history", {
                                    attrs: {
                                      symbol: _vm.company.name,
                                      "stock-exchange-id":
                                        _vm.company.stockExchanges[0]
                                          .stock_exchange_id,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "8" } },
                            [
                              _c("company-download-analysis", {
                                attrs: { company: _vm.company, data: _vm.data },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { md: "4" } },
                            [
                              _c("company-download-financial-statements", {
                                attrs: { company: _vm.company },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }