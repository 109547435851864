











































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import {ApiCompanyDataResponse, Company, Entity} from "@/smartmsi";
import Data from './Data.vue';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import StockExchangeHistory from "@/components/stock/StockExchangeHistory.vue";
import {setPageTitle} from "@/utils/meta";
import Debug from "@/components/Debug.vue";
import ScoreEvolution from "@/components/company/ScoreEvolution.vue";
import ScoreGauge from "@/components/company/ScoreGauge.vue";
import CONFIG from "@/config";


@Component({components: {ScoreGauge, ScoreEvolution, Debug, StockExchangeHistory, Data }, mixins: [formatter]})
export default class CompanyView extends Vue {

  @Prop() companyId!: number;

  startYear = '';
  endYear = '';

  dataEnabled = true;

  currentTab = 0; //this.dataEnabled ? 0 : 2;

  // used as a key in graphs for updates
  change = 0;
  
  front: string ='';

  company: Partial<Company> = {};
  data = {
    financialStatements: [],
    aggregateHeadings: [],
    axes: [],
    instrumentGraph: '',
    conclusionsGraphs: [],
  } as ApiCompanyDataResponse;

  loading = true;
  dataLoaded = false;

  flag(code: string) {
    return getUnicodeFlagIcon(code);
  }


  created() {
    this.loadCompanyData();
    this.front = CONFIG.front.url;
  }

  get latestYear() {
    return Math.max(...this.company.availableYears);
  }


  loadCompanyData() {

    this.$api.get(`company/${this.companyId}?expand=country,stockExchanges,companyActivities.activity.sector`).then(({data}) => {
      this.company = data;

      setPageTitle(this.company.name);

      this.loading = false;
      if (this.company.hasInvestments) {
        this.dataEnabled = false;
      }

      const lastYear = this.company?.availableYears.at(-1);
      if (!lastYear) {
        return;
      }

      this.change++;
    });

    this.$api.get(`company/data?id=${this.companyId}`).then(({data}) => {
      this.data.financialStatements = data.financialStatements;
      this.data.aggregateHeadings = data.aggregateHeadings;
      this.data.axes = data.axes;
      this.data.instrumentGraph = data.instrumentGraph;
      this.data.conclusionsGraphs = data.conclusionsGraphs;
      this.dataLoaded = true;
    });
  }

  onInstrumentsChange(axes: Entity[]) {
    this.change++;
    this.$set(this.data, 'axes', axes);
  }

  uploadedFinancialStatements() {
    this.loadCompanyData();
  }

  goto(id: string) {
    const el = document.getElementById(id);
    if (el) {
      const headerOffset = 125;
      const elementPosition = el.getBoundingClientRect().top;
      const offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  gotoUrl(url : string) {
      // Open the link in a new tab
      window.open(url, '_blank');
    }

}
